import React from "react";
import ReactDOM, { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import HomePage from './components/HomePage';
import AboutPage from './components/AboutPage';
import ProjectsPage from './components/ProjectsPage';
import TeamPage from './components/TeamPage';
import ContactPage from './components/ContactPage';
import Footer from './components/Footer';
// import Timer from './components/Countdown/Timer';
import Preloader from './components/Preloader/Preloader';
import './styles/styles.css';

//import Timer from "./components/Countdown/Timer";
//import SocialLinks from "./components/SocialLinks/SocialLinks";





function App() {
  return (
    // <div className="App">
    //   <div className="container">
    //     <h1 className="Logo">
    //       Fundacja Global Connect
    //     </h1>
    //     <h1>
    //       Website
    //       <br />
    //       Coming Soon
    //     </h1>
    //     <Timer />
    //     <Preloader />
    //     <SocialLinks />
    //   </div>
    // </div>

    <Router>
       <div>
         <Header />
         <Routes>
           <Route path="/" element={<HomePage />} />
           <Route path="/about" element={<AboutPage />} />
           <Route path="/projects" element={<ProjectsPage />} />
           <Route path="/team" element={<TeamPage />} />
           <Route path="/contact" element={<ContactPage />} />
         </Routes>
         <Footer />
         <Preloader />
       </div>
     </Router>
  );
}

const root = createRoot(document.getElementById("root"));
root.render(<App />);




// function App() {
//   return (
//     <Router>
//       <div>
//         {/* <Header />
//         <Routes>
//           <Route path="/" element={<HomePage />} />
//           <Route path="/about" element={<AboutPage />} />
//           <Route path="/projects" element={<ProjectsPage />} />
//           <Route path="/team" element={<TeamPage />} />
//           <Route path="/contact" element={<ContactPage />} />
//         </Routes>
//         <Footer /> */}
//         <Timer />
//         <Preloader />
//         <Optin />
//       </div>
//     </Router>
//   );
// }

// export default App;