// Footer.js
import React from 'react';
import { Typography, Container, Link } from '@mui/material';

const Footer = () => {
  return (
    <Container
      component="footer"
      style={{
        paddingTop: '1rem',
        paddingBottom: '1rem',
        backgroundColor: '#e0e0e0',
        width: '100%',  // Add this line
        maxWidth: 'none',
        // position:'fixed',
        // left:'0',
        // bottom:'0',
        // right:'0',
      }}
    >
      <div>
      <Typography variant="body2" color="textSecondary" align="center" fontFamily= '"Roboto Condensed", sans-serif'>
        © {new Date().getFullYear()} Fundacja Global Connect. All rights reserved.
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center" fontFamily= '"Roboto Condensed", sans-serif' >
        Website by <Link color="inherit" href="http://giorgi.me/" target="_blank" rel="noopener noreferrer">
          Giorgi Didberidze
        </Link>
      </Typography>
      </div>
    </Container>
  );
};

export default Footer;
