// ScrollTopButton.js
import React, { useState, useEffect } from 'react';
import { Fab } from '@mui/material';
import { KeyboardArrowUp } from '@mui/icons-material';

const ScrollTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Add scroll event listener to toggle visibility
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const threshold = 200; // Adjust this value based on when you want the button to appear

      setIsVisible(scrollTop > threshold);
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setIsVisible(false); // Hide the button after clicking
  };

  return (
    <div>
      {isVisible && (
        <Fab color="primary" size="large" style={{ position: 'fixed', bottom: '16px', right: '16px', backgroundColor: "rgb(49, 49, 177)"}} onClick={handleScrollTop}>
          <KeyboardArrowUp />
        </Fab>
      )}
    </div>
  );
};

export default ScrollTopButton;
