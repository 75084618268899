import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import ExampleCarouselImage from './ExampleCarouselImage';
import ScrollDownButton from './ScrollDownButton';
import 'bootstrap/dist/css/bootstrap.min.css';

import image1 from '../img/olsztyn.webp';
import image2 from '../img/olsztyn2.webp';
import image3 from '../img/olsztyn3.webp';

const images = [
  image2,
  image3,
  image1,
];

const slideTitles = [
  "Youth Empowerment Hub",
  "Building Brighter Tomorrows",
  "Empowering Futures",
];

const slideDescriptions = [
  "Building Resilient Futures Together",
  "Impacting Youth Through Education and Empowerment",
  "Our Mission for Youth Development",
];

const Slider = () => {
  return (
    <div style={{ position: 'relative' }}>
      <Carousel interval={7000} controls={false} pause={false} style={{ height: '100vh' }}>
        {images.map((image, index) => (
          <Carousel.Item key={index}>
            <ExampleCarouselImage text={`Slide ${index + 1}`} imageUrl={image} />
            <Carousel.Caption>
              <h1 style={{ letterSpacing: '0.2rem' }}>{slideTitles[index]}</h1>
              <p style={{color:'white'}}>{slideDescriptions[index]}</p>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
      <div style={{ position: 'absolute', bottom: '50px', left: '50%', transform: 'translateX(-50%)' }}>
        <ScrollDownButton />
      </div>
    </div>
  );
};

export default Slider;
