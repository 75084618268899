// HomePage.js

import React from 'react';
import { Link } from 'react-router-dom';
import Slider from '../components/Slider';
import ScrollTopButton from '../components/ScrollTopButton';
import { Container, Typography, Button, Grid, Paper, Avatar, IconButton } from '@mui/material';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import GroupsIcon from '@mui/icons-material/Groups';
import NaturePeopleIcon from '@mui/icons-material/NaturePeople';
import { LocationOn, Phone, Email, Facebook, Twitter, LinkedIn } from '@mui/icons-material';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import GitHubIcon from '@mui/icons-material/GitHub';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import SocialLinks from './SocialLinks/SocialLinks';

import {Card, CardContent} from '@mui/material';
import ExploreIcon from '@mui/icons-material/Explore';
import SchoolIcon from '@mui/icons-material/School';
import GroupIcon from '@mui/icons-material/Group';

const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

const seeMoreLinkStyle = {
  textDecoration: 'none',
  cursor: 'pointer',
  fontWeight: 'bold', // Add if you want a bolder text
  transition: 'color 0.3s ease-in-out', // Smooth color transition over 0.3 seconds
  paddingTop: '20px',
};

const handleSeeMoreClick = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};


const HomePage = () => {
  const bacgkroundKeyGoals = "/goals.webp"
  const backgroundContact =  "/contact.webp"
  return (
    <div>
      <Slider /> {/* Your Slider component */}
      
      {/* About Us Section */}
      <Container id="aboutSection" style={{ padding: '100px 0', textAlign: 'center', paddingLeft:'1.5rem', paddingRight:'1.5rem'}}>
        <div style={{margin: '0 auto', maxWidth: '550px'}}>
        <Typography variant="h2" gutterBottom sx={{fontSize:'35px', fontFamily: '"Roboto Condensed", sans-serif'}}>
          About Us
        </Typography>
        <Typography className="aboutUsP" variant="body1" paragraph>
        Global Connect is a foundation, non-governmental/non-profit youth organization which is based in Olsztyn, Poland. The main aim of our organization is to promote the concepts of active citizenship, EU integration, and gender equality through the development of the young individuals’ communicative and social abilities.<br></br> <br></br>
        Our organization believes that the key to stability, welfare, democracy, and tolerance is the successful integration of social and ethnic groups into society. Our mission is to increase the social integration of people of different races, cultures, sexual orientations and religions, while raising the level of knowledge and awareness of youth in the process. <br></br><br></br>
        </Typography>
        <Link to="/about" style={seeMoreLinkStyle} onClick={handleSeeMoreClick}>
            <Button variant="contained" color="primary" className='buttons'>
              See More
            </Button>
          </Link>
        </div>
      </Container>

      <Container
  style={{
    position: 'relative',
    maxWidth: 'unset',
    padding: '100px 0',
    textAlign: 'center',
    // backgroundImage: `url('/goals.jpg')`,  // Replace 'your_image_url.jpg' with the actual URL of your background image
    background: `url(${bacgkroundKeyGoals}) center/cover no-repeat fixed`,  // Replace 'your_image_url.jpg' with the actual URL of your background image
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }}
>
  {/* Overlay */}
  <div
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(49, 49, 177, 0.6)',  // Adjust the alpha value for the desired opacity
      zIndex: 1,
    }}
  ></div>

  {/* Content */}
  <div style={{ className:"divGoal", position: 'relative', zIndex: 2, margin: '0 auto', maxWidth: '960px' }}>
    <Typography variant="h2" gutterBottom sx={{ color: 'white', fontSize:'35px', fontFamily: '"Roboto Condensed", sans-serif'}}>
      Key Goals
    </Typography>
    <Grid container spacing={4}>
       {/* Goal 1 */}
       <Grid item xs={12} sm={4}>
            <Paper className='goalDiv' elevation={3} style={{ padding: '20px' }}>
              <AccessibilityNewIcon fontSize="large" color="primary" />
              <Typography variant="h5" style={{ marginTop: '10px', fontFamily: '"Roboto Condensed", sans-serif' }}>
              Youth Empowerment
              </Typography>
              <Typography variant="body1" style={{ marginTop: '10px', fontFamily: '"Roboto Condensed", sans-serif'}}>
              Empower the youth by providing non-formal educational opportunities that foster personal growth, and active engagement in the community. Promote youth-led initiatives.
              </Typography>
            </Paper>
          </Grid>

          {/* Goal 2 */}
          <Grid item xs={12} sm={4}>
            <Paper className='goalDiv' elevation={3} style={{ padding: '20px' }}>
              <GroupsIcon fontSize="large" color="primary" />
              <Typography variant="h5" style={{ marginTop: '10px', fontFamily: '"Roboto Condensed", sans-serif' }}>
                Social Inclusion
              </Typography>
              <Typography variant="body1" style={{ marginTop: '10px', fontFamily: '"Roboto Condensed", sans-serif' }}>
              The organization combats discrimination, fosters diversity, and advocates for gender equality. Minorities integration and cultural diversity for societal cohesion
              </Typography>
            </Paper>
          </Grid>

          {/* Goal 3 */}
          <Grid item xs={12} sm={4}>
            <Paper className='goalDiv' elevation={3} style={{ padding: '20px' }}>
              <NaturePeopleIcon fontSize="large" color="primary" />
              <Typography variant="h5" style={{ marginTop: '10px', fontFamily: '"Roboto Condensed", sans-serif' }}>
                Environmental Safety
              </Typography>
              <Typography variant="body1" style={{ marginTop: '10px', fontFamily: '"Roboto Condensed", sans-serif' }}>
              The organization raises eco-tolerance awareness and promotes environmental stewardship in society. Raising awareness about the importance of environmental sustainability.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
        </div>
      </Container>

      {/* Team Members Section */}
      <Container maxWidth="xl" style={{ padding: '60px 0', textAlign: 'center' }}>
      <div style={{margin: '0 auto', maxWidth: '960px'}}>
        <Typography variant="h2" gutterBottom sx={{fontSize:'35px', fontFamily: '"Roboto Condensed", sans-serif'}}>
          Our Team
        </Typography>
        <Grid className="teamDiv" container spacing={2}>
          {/* Team Member 1 */}
          <Grid item xs={12} sm={3}>
            <Avatar alt="Team Member 1" src="/oto.jpg" sx={{ width: 150, height: 150, margin: '0 auto 20px' }} />
            <Typography variant="h5" style={{ marginTop: '10px', fontFamily: '"Roboto Condensed", sans-serif' }}>
              Otari Ubilava
            </Typography>
            <Typography variant="body1" style={{ marginTop: '10px', fontFamily: '"Roboto Condensed", sans-serif' }}>
              Director & Project Manager
            </Typography>
            {/* Social Links */}
          <div style={{ marginTop: '10px', paddingBottom: '10px' }}>
            {/* Facebook */}
            <FacebookIcon
              color="primary"
              style={{ cursor: 'pointer', fontSize: '30px', marginRight: '10px' }}
              onClick={() => openInNewTab('https://www.facebook.com/oto.ubilava')}
            />

            {/* LinkedIn */}
            <LinkedInIcon
              color="primary"
              style={{ cursor: 'pointer', fontSize: '30px', marginRight: '10px' }}
              onClick={() => openInNewTab('https://www.linkedin.com/in/otari-ubilava/')}
            />

            {/* Email */}
            <EmailIcon
              color="primary"
              style={{ cursor: 'pointer', fontSize: '30px' }}
              onClick={() => window.location.href = 'mailto:otari.ubilava@coleurope.eu'}
            />
          </div>
          </Grid>

          {/* Team Member 2 */}
          <Grid item xs={12} sm={3}>
            <Avatar alt="Team Member 2" src="linkto" sx={{ width: 150, height: 150, margin: '0 auto 20px' }} />
            <Typography variant="h5" style={{ marginTop: '10px', fontFamily: '"Roboto Condensed", sans-serif' }}>
            Julia Kaczmarczyk
            </Typography>
            <Typography variant="body1" style={{ marginTop: '10px', fontFamily: '"Roboto Condensed", sans-serif' }}>
            Founder & Researcher
            </Typography>
            {/* Social Links */}
          <div style={{ marginTop: '10px', paddingBottom: '10px' }}>
            {/* Email */}
            <EmailIcon
              color="primary"
              style={{ cursor: 'pointer', fontSize: '30px' }}
              onClick={() => window.location.href = 'mailto:julia.kaczmarczyk1@interia.pl'}
            />
          </div>
          </Grid>

          {/* Team Member 3 */}
          <Grid item xs={12} sm={3}>
            <Avatar alt="Team Member 2" src="/marlena.jpeg" sx={{ width: 150, height: 150, margin: '0 auto 20px' }} />
            <Typography variant="h5" style={{ marginTop: '10px', fontFamily: '"Roboto Condensed", sans-serif' }}>
            Marlena Jaszczak
            </Typography>
            <Typography variant="body1" style={{ marginTop: '10px', fontFamily: '"Roboto Condensed", sans-serif' }}>
            Trainer & facilitator
            </Typography>
            {/* Social Links */}
          <div style={{ marginTop: '10px', paddingBottom: '10px' }}>
            {/* LinkedIn */}
            <LinkedInIcon
              color="primary"
              style={{ cursor: 'pointer', fontSize: '30px', marginRight: '10px' }}
              onClick={() => openInNewTab('https://www.linkedin.com/in/marlena-jaszczak/')}
            />

            {/* Email */}
            <EmailIcon
              color="primary"
              style={{ cursor: 'pointer', fontSize: '30px' }}
              onClick={() => window.location.href = 'mailto:marlena@intymistka.pl'}
            />
          </div>
          </Grid>

          {/* Team Member 5 */}
          <Grid item xs={12} sm={3}>
            <Avatar alt="Team Member 2" src="/gigi1.png" sx={{ width: 150, height: 150, margin: '0 auto 20px' }} />
            <Typography variant="h5" style={{ marginTop: '10px', fontFamily: '"Roboto Condensed", sans-serif' }}>
            Giorgi Didberidze
            </Typography>
            <Typography variant="body1" style={{ marginTop: '10px', fontFamily: '"Roboto Condensed", sans-serif' }}>
            Technical Integration Manager
            </Typography>
            {/* Social Links */}
          <div style={{ marginTop: '10px', paddingBottom: '10px' }}>
            {/* Personal Website */}
            <CoPresentIcon
              color="primary"
              style={{ cursor: 'pointer', fontSize: '30px', marginRight: '10px' }}
              onClick={() => openInNewTab('http://www.giorgi.me')}
            />

            {/* LinkedIn */}
            <LinkedInIcon
              color="primary"
              style={{ cursor: 'pointer', fontSize: '30px', marginRight: '10px' }}
              onClick={() => openInNewTab('https://www.linkedin.com/in/giorgi-didberidze/')}
            />

            {/* Github */}
            <GitHubIcon
              color="primary"
              style={{ cursor: 'pointer', fontSize: '30px', marginRight: '10px' }}
              onClick={() => openInNewTab('https://github.com/Gigi-Didberidze')}
            />
          </div>
          </Grid>
        </Grid>
        <Link to="/team" style={seeMoreLinkStyle} onClick={handleSeeMoreClick}>
            <p className='seeMoreP'>See More <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></p>
          </Link>
        </div>
      </Container>

      {/* Contact Us Section */}
      {/* Contact Us Section */}
<Container
  style={{
    position: 'relative',
    maxWidth: 'unset',
    padding: '100px 0',
    textAlign: 'center',
    // backgroundImage: `url('/contact.jpg')`,  // Replace 'your_image_url.jpg' with the actual URL of your background image
    background: `url(${backgroundContact}) center/cover no-repeat fixed`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }}
>
  {/* Overlay */}
  <div
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(49, 49, 177, 0.6)',  // Adjust the alpha value for the desired opacity
      zIndex: 1,
    }}
  ></div>
  
  {/* Content */}
  <div style={{ position: 'relative', zIndex: 2, margin: '0 auto', maxWidth: '960px' }}>
        <Typography variant="h2" gutterBottom sx={{color:'white', fontSize:'35px', fontFamily: '"Roboto Condensed", sans-serif'}}>
          Contact Us
        </Typography>
        <Grid container spacing={4}>
          {/* Address */}
          <Grid item xs={12} sm={4}>
            <Paper className='goalDiv' elevation={3} style={{ padding: '20px' }}>
              <LocationOn fontSize="large" color="primary" />
              <Typography variant="h5" style={{ marginTop: '10px', fontFamily: '"Roboto Condensed", sans-serif' }}>
                Our Office
              </Typography>
              <Typography variant="body1" style={{ marginTop: '10px', fontFamily: '"Roboto Condensed", sans-serif' }}>
              Ul. Bukowskiego 2, Olsztyn, Poland
              </Typography>
            </Paper>
          </Grid>

          {/* Phone */}
          <Grid item xs={12} sm={4}>
            <Paper className='goalDiv' elevation={3} style={{ padding: '20px' }}>
              <Phone fontSize="large" color="primary" />
              <Typography variant="h5" style={{ marginTop: '10px', fontFamily: '"Roboto Condensed", sans-serif' }}>
                Call Us
              </Typography>
              <Typography variant="body1" style={{ marginTop: '10px', fontFamily: '"Roboto Condensed", sans-serif' }}>
              +48 788 679 872
              </Typography>
            </Paper>
          </Grid>

          {/* Email */}
          <Grid item xs={12} sm={4}>
            <Paper className='goalDiv' elevation={3} style={{ padding: '20px' }}>
              <Email fontSize="large" color="primary" />
              <Typography variant="h5" style={{ marginTop: '10px', fontFamily: '"Roboto Condensed", sans-serif' }}>
                Email Us
              </Typography>
              <Typography variant="body1" style={{ marginTop: '10px', fontFamily: '"Roboto Condensed", sans-serif' }}>
                foundation.globalconnect@gmail.com
              </Typography>
            </Paper>
          </Grid>
        </Grid>

        {/* Social Links */}
        <SocialLinks phoneColor='white' gmailColor='white' facebookColor='white'/>
        </div>
      </Container>
      <ScrollTopButton />
    </div>
  );
};

export default HomePage;
