import React, { useState } from 'react';
import { Typography, Container, TextField, Button, Grid } from '@mui/material';
import emailjs from 'emailjs-com';
import ScrollTopButton from './ScrollTopButton';
import SocialLinks from './SocialLinks/SocialLinks';
import backgroundImageUrl from '../img/aboutContact.webp';


const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    emailjs
      .sendForm('service_mgafu9c', 'template_9zea78m', event.target, 'jwli9s537zAMxvA9K')
      .then(
        (result) => {
          console.log(result.text);
          alert('Your message has been sent successfully!');
          setFormData({
            name: '',
            email: '',
            message: '',
          });
        },
        (error) => {
          console.log(error.text);
          alert('An error occurred, please try again later.');
        }
      );
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <div
          style={{
            background: `url(${backgroundImageUrl}) center/cover no-repeat fixed`,
            height: '320px', // Adjust the height as needed
            position: 'relative',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              color: 'white',
              textAlign: 'center',
              zIndex: 1, // Add z-index to avoid overlaying text on the background image
            }}
          >
            <Typography className="aboutTitle" variant="h1" sx={{ paddingTop: '50px', fontSize: '50px', fontFamily: '"Roboto Condensed", sans-serif' }}>
              Contact Us
            </Typography>
          </div>
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // Overlay color with opacity
              zIndex: 0, // Set a lower z-index for the overlay
            }}
          />
        </div>

        <Container style={{ paddingTop: '50px', paddingBottom: '50px', flex: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
              <article style={{ maxWidth: '400px', width: '100%', textAlign: 'center' }}>
                <h2 style={{ textAlign: 'left' }}>Get in touch</h2>
                <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <div style={{ marginBottom: '1rem', width: '100%' }}>
                    <TextField
                      type="text"
                      name="name"
                      label="Name"
                      variant="outlined"
                      fullWidth
                      value={formData.name}
                      onChange={handleChange}
                      required
                      style={{ marginBottom: '1rem' }}
                    />
                    <TextField
                      type="email"
                      name="email"
                      label="Email"
                      variant="outlined"
                      fullWidth
                      value={formData.email}
                      onChange={handleChange}
                      required
                      style={{ marginBottom: '1rem' }}
                    />
                    <TextField
                      multiline
                      rows={5}
                      name="message"
                      label="Message"
                      variant="outlined"
                      fullWidth
                      value={formData.message}
                      onChange={handleChange}
                      required
                      style={{ marginBottom: '1rem' }}
                    />
                  </div>
                  <Button type="submit" variant="contained" color="primary" className='buttons' style={{ width: '30%' }}>
                    SEND
                  </Button>
                </form>
                <SocialLinks />
              </article>
              
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <article style={{ width: '100%' }}>
                {/* Google Maps Embedding */}
                <iframe
                  title="Google Maps"
                  width="100%"
                  height="320"
                  frameBorder="0"
                  style={{ border: 0 }}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23162.564001465675!2d19.40454137185592!3d53.77842263650557!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46ece9c60366a665%3A0x72e59cf062d4f01e!2sOlsztyn%2C%20Poland!5e0!3m2!1sen!2s!4v1657654084532!5m2!1sen!2s"
                  allowFullScreen
                ></iframe>

                {/* Contact Info */}
                <div style={{ marginTop: '16px', color: '#333' }}>
                  <Typography variant="h5" gutterBottom>
                    Contact Information
                  </Typography>
                  <Typography variant="body1">
                    Address: Ul. Bukowskiego 2, Olsztyn, Poland
                  </Typography>
                  <Typography variant="body1">
                    Phone: +48 788 679 872
                  </Typography>
                  <Typography variant="body1">
                    Email: foundation.globalconnect@gmail.com
                  </Typography>
                </div>
              </article>
            </Grid>
          </Grid>
        </Container>
      </div>
      <ScrollTopButton />
    </>
  );
};

export default ContactPage;
