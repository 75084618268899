import React from "react";
import { FaFacebook } from "react-icons/fa";
import { FaSquarePhone } from "react-icons/fa6";
import { SiGmail } from "react-icons/si";
import "./SocialLinks.css";

const SocialLinks = ({ phoneColor, gmailColor, facebookColor }) => {
    const iconSize = 30;
    const iconColor = "#fff";

    return (
    <div className="social-links">
      <a href="https://www.facebook.com/NGOGlobalConnect" target="_blank" rel="noopener noreferrer">
        <FaFacebook size={iconSize} style={{ color: facebookColor }}/>
      </a>
      <a href="mailto:foundation.globalconnect@gmail.com" target="_blank" rel="noopener noreferrer">
        <SiGmail size={iconSize} style={{ color: gmailColor }}/>
      </a>
      <a href="tel:+48 788 679 872" target="_blank" rel="noopener noreferrer">
        <FaSquarePhone  size={iconSize} style={{ color: phoneColor }}/>
      </a>
    </div>
  );
};

export default SocialLinks;
