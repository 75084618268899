// TeamPage.js
import React from 'react';
import { Typography, Container, Grid, Card, CardContent, Button , Avatar} from '@mui/material';
import { Link } from 'react-router-dom';
import ExploreIcon from '@mui/icons-material/Explore';
import SchoolIcon from '@mui/icons-material/School';
import GroupIcon from '@mui/icons-material/Group';
import ScrollTopButton from '../components/ScrollTopButton';
import backgroundImageUrl from '../img/team.webp';

const TeamPage = () => {
  return (
    <>
      <div
        style={{
          background: `url(${backgroundImageUrl}) center/cover no-repeat fixed`,
          height: '320px', // Adjust the height as needed
          position: 'relative',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
            textAlign: 'center',
            zIndex: 1, // Add z-index to avoid overlaying text on the background image
          }}
        >
          <Typography className="aboutTitle" variant="h1" sx={{paddingTop:'50px', fontSize:'50px', fontFamily: '"Roboto Condensed", sans-serif'}}>Team</Typography>
        </div>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Overlay color with opacity
            zIndex: 0, // Set a lower z-index for the overlay
          }}
        />
      </div>
      <Container style={{ paddingTop: '50px', paddingBottom: '50px' }}>
        <Typography variant="body1" paragraph className='toLine'>
        Global Connect team brings prior experience in Youth in Action programs, encompassing:
        
        </Typography>

        <ul>
              <li>Planning and managing projects is our specialty! including weekly montlhy and annual reports.</li>
              <li>Specialization in Human rights protection, including but not limited ethnic, racial, religios, natinal and sexual minorities</li>
              <li>Specialization in conflict resolution</li>
              <li>Specialization in social media platform and campaign management</li>
              <li>Extensive experience in in youth work, are familiar on youth work standards</li>
              <li>Specialization in environment friendly practices and nature protection</li>
            </ul>


        {/* Our Team Section */}
<section style={{ marginBottom: '50px' }}>
  <Typography variant="h4" gutterBottom>
    Our Team
  </Typography>
  <Grid container spacing={3}>
    {[
      { name: 'Otari Ubilava', role: 'Director & Project Manager', description: "Otari is an ex-College of Europe graduate with a master's degree in European Interdisciplinary Studies, majoring in European Neighborhood Policy. Working in the same field since 2014, thanks to the Eastern Partnership and Erasmus projects, he has acquired an extensive insight into management and youth work. He has pleasantly carried out event management for multiple larger events within the organization 'International Monitoring Network'.", image: '/oto.jpg' },
      { name: 'Julia Kaczmarczyk', role: 'Founder & Researcher', description: "Julia, the founder of the organization, possess an extensive experience in project management. She is a master in business administration and management. She is familiar with youth work and have organized multiple activities on local level in Olsztyn."},
      { name: 'Marlena Jaszczak', role: 'Trainer & Facilitator', description: 'Bringing extensive insight into education and youth work. She consults and educates on psychology, gender equality, sex education and is an experienced youth worker trainer/facilitator.',image: '/marlena.jpeg' },
      { name: 'Giorgi Didberidze', role: 'Technical Integration Manager', description: 'Giorgi, a Computer Science MSc from Eötvös Loránd University, Budapest has an extensive experience in various youth projects on the national and international levels along with technical skills. He is a facilitator and a youth worker and has proved to be outstanding in the peacebuilding and inclusion sector.',image: '/gigi1.png' },
    ].map((teamMember, index) => (
      <Grid item xs={12} md={4} key={index}>
        <Card style={{ height: '100%' }}>
          <CardContent style={{ textAlign: 'center', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Avatar
              src={teamMember.image}
              alt={`Team Member ${index + 1}`}
              sx={{ width: 80, height: 80, marginBottom: '1rem' }}
            />
            <Typography variant="h6" gutterBottom>
              {teamMember.name}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {teamMember.role}
            </Typography>
            <Typography variant="body2" color="textSecondary" style={{ marginTop: '0.5rem' }}>
              {teamMember.description}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    ))}
  </Grid>
</section>
      </Container>
      <ScrollTopButton />
    </>
   
  );
};

export default TeamPage;

