import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  Box,
  useMediaQuery,
  IconButton,
  Popover,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import WorkIcon from '@mui/icons-material/Work';
import PeopleIcon from '@mui/icons-material/People';
import EmailIcon from '@mui/icons-material/Email';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const isMobileSize = useMediaQuery('(max-width:768px)');

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const scrolled = scrollPosition > 50;
    setIsScrolled(scrolled);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const isHomepage = location.pathname === '/' && !isMobileSize;

  return (
    <>
      <AppBar
        position="fixed"
        elevation={isScrolled && isHomepage ? 0 : 4}
        style={{
          backgroundColor: isHomepage ? (isScrolled ? 'rgb(49, 49, 177)' : 'transparent') : 'rgb(49, 49, 177)',
          boxShadow: 'none',
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '10px', // Padding for space around the text
            }}
          >
            <img
              src="/1.png" // Replace with the path to your custom image
              alt="Custom Icon"
              style={{ marginRight: '5px', width: '30px', height: '30px' }} // Adjust width and height as needed
            />
            <Typography
              variant="h6"
              component={Link}
              to="/"
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
              style={{
                textDecoration: 'none',
                color: 'white',
                fontFamily: '"Roboto Condensed", sans-serif',
              }}
            >
              Fundacja Global Connect
            </Typography>
          </div>
          {isMobileSize && (
            <>
              <IconButton
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={handleMenuClick}
                sx={{ fontSize: '2rem', marginRight:'unset'}} // Adjust the icon size as needed
              >
                <MenuIcon fontSize='large'/>
              </IconButton>
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleMenuClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <List>
                  <ListItem
                    button
                    component={Link}
                    to="/"
                    onClick={(event) => {
                      handleMenuClose(event);
                      window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top
                    }}
                  >
                    <ListItemIcon>
                      <HomeIcon />
                    </ListItemIcon>
                    <ListItemText className="mobileList" primary="Home" />
                  </ListItem>
                  <ListItem
                    button
                    component={Link}
                    to="/about"
                    onClick={(event) => {
                      handleMenuClose(event);
                      window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top
                    }}
                  >
                    <ListItemIcon>
                      <InfoIcon />
                    </ListItemIcon>
                    <ListItemText className="mobileList" primary="About" />
                  </ListItem>
                  <ListItem
                    button
                    component={Link}
                    to="/projects"
                    onClick={(event) => {
                      handleMenuClose(event);
                      window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top
                    }}
                  >
                    <ListItemIcon>
                      <WorkIcon />
                    </ListItemIcon>
                    <ListItemText className="mobileList" primary="Projects" />
                  </ListItem>
                  <ListItem
                    button
                    component={Link}
                    to="/team"
                    onClick={(event) => {
                      handleMenuClose(event);
                      window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top
                    }}
                  >
                    <ListItemIcon>
                      <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText className="mobileList" primary="Team" />
                  </ListItem>
                  <ListItem
                    button
                    component={Link}
                    to="/contact"
                    onClick={(event) => {
                      handleMenuClose(event);
                      window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top
                    }}
                  >
                    <ListItemIcon>
                      <EmailIcon />
                    </ListItemIcon>
                    <ListItemText className="mobileList" primary="Contact" />
                  </ListItem>
                </List>
              </Popover>
            </>
          )}
          {!isMobileSize && (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Button
                color="inherit"
                component={Link}
                to="/"
                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
              >
                <HomeIcon sx={{ marginRight: '0.5rem' }} />
                Home
              </Button>
              <Button
                color="inherit"
                component={Link}
                to="/about"
                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
              >
                <InfoIcon sx={{ marginRight: '0.5rem' }} />
                About
              </Button>
              <Button
                color="inherit"
                component={Link}
                to="/projects"
                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
              >
                <WorkIcon sx={{ marginRight: '0.5rem' }} />
                Projects
              </Button>
              <Button
                color="inherit"
                component={Link}
                to="/team"
                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
              >
                <PeopleIcon sx={{ marginRight: '0.5rem' }} />
                Team
              </Button>
              <Button
                color="inherit"
                component={Link}
                to="/contact"
                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
              >
                <EmailIcon sx={{ marginRight: '0.5rem' }} />
                Contact
              </Button>
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
