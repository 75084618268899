// ScrollDownButton.js
import React from 'react';
import IconButton from '@mui/material/IconButton';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { Link as ScrollLink } from 'react-scroll';

const ScrollDownButton = () => {
  const buttonStyle = {
    borderRadius: '50%',
    backgroundColor: 'transparent',
    border: '2px solid #FFFFFF', // Blue border
    transition: 'transform 0.3s ease', // Added padding transition
    padding: '8px', // Initial padding
  };

  return (
    <ScrollLink to="aboutSection" smooth duration={200}>
      <IconButton
        color="primary"
        style={buttonStyle}
        sx={{
          '&:hover': {
            transform: 'scale(1.2)',
            padding: '12px', // Padding on hover
          },
        }}
      >
        <KeyboardDoubleArrowDownIcon fontSize="large" style={{ color: '#FFFFFF' }} />
      </IconButton>
    </ScrollLink>
  );
};

export default ScrollDownButton;
