import React from 'react';
import PropTypes from 'prop-types';
import '../styles/ExampleCarouselImage.css'; // Import the CSS file for styling

const ExampleCarouselImage = ({ text, imageUrl }) => {
  return (
    <div className="image-container">
      <img src={imageUrl} alt={text} className="zoom-in" />
      <div className="overlay"></div>
    </div>
  );
};

ExampleCarouselImage.propTypes = {
  text: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
};

export default ExampleCarouselImage;
