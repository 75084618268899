import React from 'react';
import { Typography, Container, Grid, Card, CardContent, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import ExploreIcon from '@mui/icons-material/Explore';
import SchoolIcon from '@mui/icons-material/School';
import GroupIcon from '@mui/icons-material/Group';
import ScrollTopButton from '../components/ScrollTopButton';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import backgroundImageUrl from '../img/aboutContact.webp';

const AboutPage = () => {
  return (
    <>
      <div
        style={{
          background: `url(${backgroundImageUrl}) center/cover no-repeat fixed`,
          height: '320px', // Adjust the height as needed
          position: 'relative',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
            textAlign: 'center',
            zIndex: 1, // Add z-index to avoid overlaying text on the background image
          }}
        >
          <Typography className="aboutTitle" variant="h1" sx={{paddingTop:'50px', fontSize:'50px', fontFamily: '"Roboto Condensed", sans-serif'}}>About Us</Typography>
        </div>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Overlay color with opacity
            zIndex: 0, // Set a lower z-index for the overlay
          }}
        />
      </div>
      <Container style={{ paddingTop: '50px', paddingBottom: '50px' }}>
        <Typography variant="body1" paragraph className='toLine'>
        The main aim of our organization is to promote the concepts of active citizenship, 
          EU integration, and gender equality through the development of the young individuals’ communicative and social abilities.
        
        </Typography>

        {/* Mission & Vision Section */}
        <section style={{ marginBottom: '50px' }}>
          <Typography variant="h4" gutterBottom>
            Mission & Vision
          </Typography>
          <Typography variant="body1" paragraph className='toLine'>
          Our organization currently operates with a vision of the present time which actually aims at changing lives of people living in the community
        through the understanding of active citizenship, capacity building, tolerance, democracy and effective communication. 
        The activity is facilitated through the application of internationally certified methodologies that have been commended by specialized organizations as 
        well through knowledge and expertise exchange.
          </Typography>
          <ul>
              <li>Tackling discrimination and fostering inclusivity and acceptance of different viewpoints and behaviors.</li>
              <li>Enabling a stable integration of minorities and immigrants into local populations.</li>
              <li>By creating an environment where youth find themselves through non-formal educational methods.</li>
              <li>European young people's involvement in European educational programmes to promote active citizenship and European values to the Polish youth.</li>
              <li>In other terms, developing the tolerance of multiculturalism, assuring cultural diversity and making people from different backgrounds cooperative with one another.</li>
              <li>The amplification of the role of the youth who are participating less and the search for new ways to guarantee that the local community is ever more influenced by non formal education.</li>
              <li>Through disseminating information about what is happening already in the ecosystem and encouraging the adaption of eco-tolerance within society.</li>
            </ul>
        </section>

        {/* Key Initiatives Section */}
<section style={{ marginBottom: '50px' }}>
  <Typography variant="h4" gutterBottom>
    Key Initiatives
  </Typography>
  <Grid container spacing={3}>
    <Grid item xs={12} md={4}>
      <Card style={{ height: '100%' }}>
        <CardContent style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <FollowTheSignsIcon fontSize="large" color="primary" sx={{ marginBottom: '1rem' }} />
          <Typography variant="h6" gutterBottom>
          Youth Development
          </Typography>
          <Typography variant="body2" color="textSecondary">
          Creating an environment for youth development through non-formal educational methods. 
          Empowering young individuals by providing opportunities for personal growth, active engagement in the community, and participation in European educational programs.
          </Typography>
        </CardContent>
      </Card>
    </Grid>
    <Grid item xs={12} md={4}>
      <Card style={{ height: '100%' }}>
        <CardContent style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <Diversity2Icon fontSize="large" color="primary" sx={{ marginBottom: '1rem' }} />
          <Typography variant="h6" gutterBottom>
          Diversity
          </Typography>
          <Typography variant="body2" color="textSecondary">
          Enabling stable integration of minorities and immigrants into local populations. 
          Emphasizing the importance of cultural diversity for societal cohesion, and advocating for equal opportunities and acceptance.
          </Typography>
        </CardContent>
      </Card>
    </Grid>
    <Grid item xs={12} md={4}>
      <Card style={{ height: '100%' }}>
        <CardContent style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <GroupIcon fontSize="large" color="primary" sx={{ marginBottom: '1rem' }} />
          <Typography variant="h6" gutterBottom>
            Community Building
          </Typography>
          <Typography variant="body2" color="textSecondary">
          Actively involving European youth in educational programs to promote active citizenship and European values to the Polish youth. 
          Fostering collaboration among young people from different cultural backgrounds, encouraging global perspectives, and building a network for cross-cultural understanding.
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  </Grid>
</section>

      </Container>
      <ScrollTopButton />
    </>
   
  );
};

export default AboutPage;
