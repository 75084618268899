import React, { useState } from 'react';
import { Typography, Container, TextField, Button } from '@mui/material';
import emailjs from 'emailjs-com';
import backgroundImageUrl from '../img/projects.webp';
import ScrollTopButton from './ScrollTopButton';

const ProjectsPage = () => {
  const [formData, setFormData] = useState({
    email: '',
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    emailjs
      .sendForm('service_mgafu9c', 'template_h8bjct5', event.target, 'jwli9s537zAMxvA9K')
      .then(
        (result) => {
          console.log(result.text);
          alert('Your message has been sent successfully!');
          setFormData({
            email: '',
          });
        },
        (error) => {
          console.log(error.text);
          alert('An error occurred, please try again later.');
        }
      );
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  return (
    <>
      <div
        style={{
          background: `url(${backgroundImageUrl}) center/cover no-repeat fixed`,
          height: '320px', // Adjust the height as needed
          position: 'relative',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
            textAlign: 'center',
            zIndex: 1, // Add z-index to avoid overlaying text on the background image
          }}
        >
          <Typography className="aboutTitle" variant="h1" sx={{ paddingTop: '50px', fontSize: '50px', fontFamily: '"Roboto Condensed", sans-serif' }}>
            Our Projects
          </Typography>
        </div>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Overlay color with opacity
            zIndex: 0, // Set a lower z-index for the overlay
          }}
        />
      </div>

      <Container style={{ paddingTop: '50px', paddingBottom: '50px', minHeight: 'calc(100% - 320px)' }}>
        <Typography variant="body1" paragraph className='toLine'>
          The main aim of our organization is to promote the concepts of active citizenship,
          EU integration, and gender equality through the development of the young individuals’ communicative and social abilities.
          Stay tuned for our upcoming projects that will focus on making a positive impact in our community and beyond.
        </Typography>

        <section style={{ display: 'flex', justifyContent: 'center' }}>
          <article style={{ maxWidth: '400px', width: '100%', textAlign:'center', paddingTop:'40px'}}>
            <img src='/uptodate.webp' alt="logo" style={{width:'158px',objectFit:'cover', height: '158px', borderRadius: '50%', boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)', marginBottom:'30px'}}/>
            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div style={{ marginBottom: '1rem', width: '100%' }}>
                <TextField
                  type="email"
                  name="email"
                  label="Enter your email"
                  variant="outlined"
                  fullWidth
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <Button className="buttons" type="submit" variant="contained" color="primary" style={{ width: '30%' }}>
                APPLY
              </Button>
            </form>
          </article>
        </section>
      </Container>
      <ScrollTopButton />
    </>
  );
};

export default ProjectsPage;
